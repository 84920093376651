import { fltRound } from '~/utils/functions';

interface Soma {
  pValBonif: number;
  pQtdRateio: number;
  vSaldoRateio: number;
}

export function CalcSaldoRateio(
  pPerRateio: number,
  pQtdRateio: number,
  pValBonif: number,
  somaQtdTotalBonf: number,
  somaValTotalBonf: number,
  ratear: number,
): Soma {
  let vSaldoRateio = 0;
  switch (ratear) {
    case 0:
      pQtdRateio = somaQtdTotalBonf;
      if (pValBonif > 0 && somaQtdTotalBonf !== null) {
        pValBonif = somaValTotalBonf - pValBonif;
      } else if (somaValTotalBonf !== null) {
        pValBonif = somaValTotalBonf;
      } else {
        pValBonif = 0.0;
      }
      return {
        pValBonif,
        pQtdRateio,
        vSaldoRateio,
      };
    case 1:
      if (pPerRateio > 0) {
        vSaldoRateio = 100 - pPerRateio;
      } else {
        vSaldoRateio = 100;
      }
      if (pQtdRateio > 0 && somaQtdTotalBonf !== null) {
        pQtdRateio = Math.abs(somaQtdTotalBonf - pQtdRateio);
      } else if (somaQtdTotalBonf !== null) {
        pQtdRateio = somaQtdTotalBonf;
      } else {
        pQtdRateio = 0.0;
      }
      return {
        pValBonif,
        pQtdRateio,
        vSaldoRateio,
      };
    default:
      return {
        pValBonif,
        pQtdRateio,
        vSaldoRateio,
      };
  }
}
