import React from 'react';
import { AlteracaoCustoProvider } from './AlteracaoNfBonificadaContext';
import MemoContent from './AlteracaoNfBonificadaContent';

export const AlteracaoCustoNfBonificada: React.FC = () => {
  return (
    <AlteracaoCustoProvider>
      <MemoContent />
    </AlteracaoCustoProvider>
  );
};
