import { yupResolver } from '@hookform/resolvers/yup';
import {
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { ChangeEvent, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaEraser } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { toast } from 'react-toastify';
import { InputAsyncSelect, InputNumber } from '~/components/NovosInputs';
import { errorHandler } from '~/utils/ErrorHandler';
import { moneyFormat } from '~/utils/functions';

import { useAlteracaoCustoNfBonificada } from '../../AlteracaoNfBonificadaContext';
import { NotaFiscal } from '../../protocols';
import { getNfs } from '../../services/api';
import { BootstrapModal } from './styles';
import { schema } from './validations';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

export const Modal: React.FC = () => {
  const {
    register,
    getValues,
    setValue,
    control,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [nfs, setNfs] = useState<NotaFiscal[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(5);
  const [nfSelecionada, setNfSelecionada] = useState<NotaFiscal>();
  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const {
    showModal,
    handleShowModal,
    fornecedorModal,
    setFornecedorModal,
    loading,
    loadingItems,
    nfsSelecionadas,
    setNfsSelecionadas,
    nfEntrada,
    getValues: getValuesContext,
  } = useAlteracaoCustoNfBonificada();

  const handleLoadItemsModal = async (nf: NotaFiscal) => {
    setNfSelecionada(nf);
  };

  const getValueByKey = <K extends keyof Record<string, unknown>>(
    obj: Record<string, unknown>,
    key: K,
  ): Record<string, unknown>[K] | null =>
    key in obj ? (obj[key] as Record<string, unknown>[K]) : null;

  const handleSubmitModal = async () => {
    const watchFornecedor = watch('cod_pessoa');

    if (watchFornecedor === undefined) {
      setError('cod_pessoa', { type: 'focus' });
      return;
    }
    const { num_nf } = getValues();
    try {
      const value =
        getValueByKey(fornecedorModal, 'cod_pessoa') ?? fornecedorModal.value;

      const getNfsService = await getNfs(num_nf, value);
      setNfSelecionada(undefined);
      setNfs(getNfsService);
    } catch (err: any) {
      setNfs([]);
      return errorHandler(err);
    }
  };

  const verificaExistenciaNf = () => {
    if (!nfSelecionada) {
      throw new Error('Selecione uma Nota Fiscal');
    }
    const existingNf = nfsSelecionadas.some(
      (nf) => nf.cod_seq_nf === nfSelecionada?.cod_seq_nf,
    );
    if (existingNf) {
      throw new Error('NF já relacionada no rateio');
    }
    const ratear = getValuesContext('ratear');
    if (ratear.value === 0) {
      if (Number(nfSelecionada?.qtd_total_bonificado_rateado) > 0) {
        throw new Error(
          'Esta nota já foi utilizada para rateio por Qtde., não será possível utilizá-la por Valor!',
        );
      }
    } else if (Number(nfSelecionada?.val_total_bonificado_rateado) > 0) {
      throw new Error(
        'Esta nota já foi utilizada para rateio por Valor, não será possível utilizá-la por Qtde.!',
      );
    }
    setNfs(() =>
      nfs.filter((nf) => nf.cod_seq_nf !== nfSelecionada?.cod_seq_nf),
    );
    setNfsSelecionadas([...nfsSelecionadas, nfSelecionada]);
    setNfSelecionada(undefined);
    return 'NF foi relacionada ao rateio';
  };

  const handleSubmit = () => {
    try {
      const message = verificaExistenciaNf();
      toast.success(message);
    } catch (error: any) {
      toast.warn(error?.message);
    }
  };

  const onChangedFornecedor = (selected: any) => {
    clearErrors();
    setValue('cod_pessoa', selected);
    setFornecedorModal(selected);
  };

  const useStyles = makeStyles(() => ({
    customWidth: {
      maxWidth: 550,
      fontSize: 12,
    },
  }));

  const styles = useStyles();

  return (
    <BootstrapModal
      show={showModal}
      onHide={handleShowModal}
      centered
      size="xl"
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title>Selecionar a NF Bonificada</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <Row>
          <Col>
            <InputNumber
              label="Nº NF"
              max={99999999999}
              maxLength={50}
              placeholder="0"
              name="num_nf"
              type="number"
              register={register}
              onPaste={(event: any) => {
                const { clipboardData } = event;
                const pastedText = clipboardData.getData('text');
                if (/[a-zA-Z]/.test(pastedText)) {
                  const numericText = pastedText.replace(/[^0-9]/g, '');
                  setValue('num_nf', numericText);
                  event.preventDefault();
                }
              }}
              onKeyDown={(e: any) => {
                if ([69].includes(e.keyCode)) {
                  e.preventDefault();
                }
              }}
              onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                setValue('num_nf', String(ev.target.value));
              }}
              disabled={false}
              isError={false}
            />
          </Col>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
            sm={12}
            md={12}
            lg={12}
            xl={4}
          >
            <InputAsyncSelect
              label="Fornecedor"
              maxLength={50}
              placeholder="Selecione..."
              name="cod_pessoa"
              register={register}
              defaultValue={`${fornecedorModal.cod_fornecedor} - ${fornecedorModal.label}`}
              control={control}
              changeSelected={onChangedFornecedor}
              api={{
                route: '/nf-outras-operacoes/fornecedor',
                method: 'get',
                fields: ['cod_fornecedor', 'des_fornecedor'],
              }}
              isError={!!errors.cod_pessoa}
            />
          </Col>

          <Col
            xl={2}
            lg={12}
            md={12}
            sm={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButtonNovo
              onClick={async () => {
                await handleSubmitModal();
                setPage(0);
              }}
              icon={IoIosSearch}
              showLoading={loading}
              label="Pesquisar"
            />
          </Col>
          <Col
            xl={2}
            lg={12}
            md={12}
            sm={12}
            style={{
              marginTop: '3px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButtonNovo
              onClick={() => {
                setNfs([]);
                setFornecedorModal({
                  value: nfEntrada?.cod_pessoa,
                  cod_fornecedor: nfEntrada?.cod_fornecedor,
                  label: `${nfEntrada?.cod_fornecedor} - ${nfEntrada?.des_fornecedor}`,
                });
                reset({
                  cod_pessoa: {
                    value: nfEntrada?.cod_pessoa,
                    cod_fornecedor: nfEntrada?.cod_fornecedor,
                    label: `${nfEntrada?.cod_fornecedor} - ${nfEntrada?.des_fornecedor}`,
                  },
                });
                setPage(0);
              }}
              icon={MdOutlineCancel}
              variant="cancel"
              label="Cancelar"
            />
          </Col>
          {/* LIMPAR */}
          <Col
            xl={2}
            lg={12}
            md={12}
            sm={12}
            style={{
              display: 'flex',
              marginTop: '3px',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButtonNovo
              onClick={() => {
                reset();
                setNfs([]);
                setFornecedorModal({
                  value: nfEntrada?.cod_pessoa,
                  cod_fornecedor: nfEntrada?.cod_fornecedor,
                  label: `${nfEntrada?.cod_fornecedor} - ${nfEntrada?.des_fornecedor}`,
                });
                reset({
                  num_nf: '',
                  cod_pessoa: {
                    value: nfEntrada?.cod_pessoa,
                    cod_fornecedor: nfEntrada?.cod_fornecedor,
                    label: `${nfEntrada?.cod_fornecedor} - ${nfEntrada?.des_fornecedor}`,
                  },
                });
                setPage(0);
              }}
              icon={FaEraser}
              variant="clear"
              label="Limpar"
            />
          </Col>
        </Row>
        <Paper
          style={{
            width: '100%',
            flex: 1,
            zIndex: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TableContainer style={{ maxHeight: '250px', zIndex: '0' }}>
            <Table stickyHeader style={{ minHeight: 100 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: '8%' }}>
                    NF
                  </TableCell>
                  <TableCell align="center" style={{ width: '5%' }}>
                    Série
                  </TableCell>
                  <TableCell align="center" style={{ width: '5%' }}>
                    Rateio por
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    Fornecedor
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    Valor
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    Entrada
                  </TableCell>
                  <TableCell align="center" style={{ width: '15%' }}>
                    Saldo Val. Bonif
                  </TableCell>
                  <TableCell align="center" style={{ width: '15%' }}>
                    Saldo Qtd. Bonif
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ maxHeight: '100px' }}>
                {nfs.length > 0 &&
                  nfs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((nf: any) => {
                      let des_sit_rateio = String(nf.des_sit_rateio);
                      const parts = des_sit_rateio.split(':');

                      if (parts.length > 1) {
                        const [integerPartRaw, decimalPartRaw] =
                          parts[1].split(',');

                        const integerPart = integerPartRaw || '00';
                        const decimalPart = decimalPartRaw || '00';

                        const value = `${integerPart.trim()},${decimalPart.trim()}`;

                        if (parts[0].trim() !== 'NF ainda não utilizada')
                          des_sit_rateio = `${parts[0].trim()}: ${value}`;
                      }

                      return (
                        <TableRow
                          onClick={() => handleLoadItemsModal(nf)}
                          key={nanoid()}
                          style={{
                            background:
                              nfSelecionada?.cod_seq_nf === nf.cod_seq_nf
                                ? '#dcfcfb'
                                : '',
                          }}
                        >
                          <TableCell align="center">{nf.num_nf}</TableCell>
                          <TableCell align="center">
                            {nf.num_serie_nf}
                          </TableCell>
                          <Tooltip
                            title={des_sit_rateio}
                            classes={{ tooltip: styles.customWidth }}
                          >
                            <TableCell
                              style={{
                                maxWidth: '145px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              align="center"
                            >
                              {des_sit_rateio}
                            </TableCell>
                          </Tooltip>
                          <Tooltip
                            title={nf.des_fornecedor}
                            classes={{ tooltip: styles.customWidth }}
                          >
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {nf.des_fornecedor}
                            </TableCell>
                          </Tooltip>
                          <TableCell
                            style={{
                              maxWidth: '200px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            align="center"
                          >{`R$ ${moneyFormat(nf.val_total_nf)}`}</TableCell>
                          <TableCell align="center">
                            {moment(nf.dta_entrada).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: '200px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            align="center"
                          >
                            {`R$ ${moneyFormat(nf.val_total_bonificado)}`}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(nf.qtd_total_bonificado)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
              {nfs.length === 0 && (
                <div
                  style={{
                    left: '0',
                    right: '0',
                    marginTop: '0px',
                    textAlign: 'center',
                    position: 'absolute',
                  }}
                >
                  Nenhum registro encontrado com o critério informado
                </div>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[-1]}
            onPageChange={handleChangePage}
            count={nfs.length}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Paper>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        {loadingItems && (
          <>
            <CircularProgress size={20} style={{ color: '#46057e' }} />
            <h5>Pesquisando Itens...</h5>
          </>
        )}
        <Button variant="secondary" onClick={handleSubmit}>
          Adicionar
        </Button>
        <Button variant="secondary" onClick={handleShowModal}>
          Fechar
        </Button>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};
