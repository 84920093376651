import { Produto, NotaFiscal } from '../protocols';

export function ratearNF(
  produtos: Produto[],
  ratear: number,
  nfsSelecionadas: NotaFiscal[],
): NotaFiscal[] {
  const total_val_rateio = produtos.reduce((acc: number, item: Produto) => {
    return acc + item.val_rateio;
  }, 0);

  const total_qtd_rateada = produtos.reduce((acc: number, item: Produto) => {
    return acc + item.qtd_rateada - item.qtd_total_bonificado_rateado;
  }, 0);
  let valor_ajustado_por_nf = 0;
  let valor_rateado_usuario =
    ratear === 0 ? total_val_rateio : total_qtd_rateada;
  const newNf = nfsSelecionadas.map((nf: NotaFiscal) => {
    if (valor_rateado_usuario === 0) {
      return nf;
    }
    if (ratear === 0) {
      if (valor_rateado_usuario > nf.val_total_bonificado) {
        valor_ajustado_por_nf = nf.val_total_bonificado;
        valor_rateado_usuario -= nf.val_total_bonificado;
        return {
          ...nf,
          val_total_atualizar:
            valor_ajustado_por_nf + nf.val_total_bonificado_rateado,
        };
      }
      valor_ajustado_por_nf = valor_rateado_usuario;
      valor_rateado_usuario = 0;
      return {
        ...nf,
        val_total_atualizar:
          valor_ajustado_por_nf + nf.val_total_bonificado_rateado,
      };
    }
    if (valor_rateado_usuario > nf.qtd_total_bonificado) {
      valor_ajustado_por_nf = nf.qtd_total_bonificado;
      valor_rateado_usuario -= nf.qtd_total_bonificado;
      return {
        ...nf,
        val_total_atualizar:
          valor_ajustado_por_nf + nf.qtd_total_bonificado_rateado,
      };
    }
    valor_ajustado_por_nf = valor_rateado_usuario;
    valor_rateado_usuario = 0;
    return {
      ...nf,
      val_total_atualizar:
        valor_ajustado_por_nf + nf.qtd_total_bonificado_rateado,
    };
  });
  return newNf;
}
