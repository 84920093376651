import api from '~/services/api';
import { NotaFiscal, Produto } from '../protocols';

export async function getNfs(
  num_nf: string,
  cod_fornecedor: string | undefined,
): Promise<NotaFiscal[]> {
  let path = '/nf-bonificada';
  num_nf === '' || num_nf === undefined
    ? (path = `${path}/${cod_fornecedor}`)
    : (path = `${path}/${cod_fornecedor}/${num_nf}`);
  const res = await api.get(path);
  const { message, success } = res.data;
  if (!success) {
    throw new Error(message);
  }
  return message;
}

export async function getProductsForNf(
  cod_loja: number | undefined,
  cod_nf: number | undefined,
): Promise<Produto[]> {
  const res = await api.get(`/nf-bonificada/produtos/${cod_loja}/${cod_nf}`);
  const { message, success } = res.data;
  if (success) {
    const forEachProducts = message.map((item: any) => {
      const produto = item;
      return produto;
    });
    return forEachProducts;
  }
  throw new Error(message);
}

export async function updateItemsAndNfs(
  newProdutos: Produto[],
  newsNf: NotaFiscal[],
  ratear: number,
): Promise<string> {
  const req = await api.put('/nf-bonificada', {
    newProdutos,
    newsNf,
    ratear,
  });

  const { success, message } = req.data;
  if (success) {
    return message;
  }
  throw new Error(message);
}
