import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import { v4 } from 'uuid';
import { moneyFormat } from '~/utils/functions';
import { ContainerTotais } from '../../style';
import { useAlteracaoCustoNfBonificada } from '../../AlteracaoNfBonificadaContext';
import { NotaFiscal, Produto } from '../../protocols';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Row } from 'react-bootstrap';
import { MdDeleteForever } from 'react-icons/md';

const MySwal = withReactContent(Swal);

export const TableNfSelecionada: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(3);

  const {
    nfsSelecionadas,
    produtos,
    setNfsSelecionadas,
    somaValTotalBonf,
    somaQtdTotalBonf,
    setProdutos,
    clearErrors,
  } = useAlteracaoCustoNfBonificada();

  const handleChangePage = async (
    event: unknown,
    newPage: number,
  ): Promise<void> => {
    const startIndex = newPage * rowsPerPage;
    if (startIndex >= nfsSelecionadas.length) {
      if (newPage > 0) {
        setPage(newPage - 1);
      } else {
        setPage(0);
      }
    } else {
      setPage(newPage);
    }
  };
  const handleDeleteNf = (nf: NotaFiscal): void => {
    MySwal.fire({
      title: 'Remover NF bonificada',
      text: 'Deseja remover a NF bonificada da relação de rateio?',
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const filterNf = nfsSelecionadas.filter(
          (item: NotaFiscal) => item.cod_seq_nf !== nf.cod_seq_nf,
        );
        produtos.forEach((item: Produto) => {
          if (filterNf.length === 0) {
            item.qtd_rateada = 0;
          }
        });
        setProdutos(produtos);
        setNfsSelecionadas(filterNf);
        clearErrors();
        if (filterNf.length % rowsPerPage === 0) {
          if (page > 0) {
            setPage(page - 1);
          }
        }
      }
    });
  };

  return (
    <>
      <Paper
        style={{
          width: '100%',
          flex: 1,
          zIndex: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TableContainer
          className="col-sm-12"
          style={{ maxHeight: 250, minHeight: 250 }}
        >
          <Table stickyHeader>
            <TableHead style={{ whiteSpace: 'nowrap' }}>
              <TableRow>
                <TableCell align="center">Cód. Forn.</TableCell>
                <TableCell align="center">CNPJ</TableCell>
                <TableCell align="center">Núm. NF</TableCell>
                <TableCell align="center">Série</TableCell>
                <TableCell align="center">Qtd Bonif.</TableCell>
                <TableCell align="center">Valor Total Bonif.</TableCell>
                <TableCell align="center">Data Entrada</TableCell>
                <TableCell align="center">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nfsSelecionadas.length > 0 &&
                nfsSelecionadas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((nf: any) => (
                    <TableRow key={v4()}>
                      <TableCell align="center">{nf.cod_fornecedor}</TableCell>
                      <TableCell align="center">
                        {nf.num_cpf_cnpj.replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          '$1.$2.$3/$4-$5',
                        )}
                      </TableCell>
                      <TableCell align="center">{nf.num_nf}</TableCell>
                      <TableCell align="center">{nf.num_serie_nf}</TableCell>
                      <TableCell align="center">
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(nf.qtd_total_bonificado)}
                      </TableCell>
                      <TableCell align="center">
                        {`R$ ${moneyFormat(nf.val_total_bonificado)}`}
                      </TableCell>
                      <TableCell align="center">
                        {moment(nf.dta_entrada).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          type="button"
                          className="bg-transparent"
                          style={{
                            borderColor: 'transparent',
                          }}
                          onClick={() => {
                            handleDeleteNf(nf);
                          }}
                        >
                          <MdDeleteForever color="red" size={35} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[-1]}
          onPageChange={handleChangePage}
          count={nfsSelecionadas.length}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
      <ContainerTotais>
        <Row id="linha-item-total">
          Total Disponível
          <div className="coluna-item-total">
            <span>Qtde. Bonif:</span>
          </div>
          <div className="coluna-item-total">
            <span>
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(somaQtdTotalBonf)}
            </span>
          </div>
          <div className="coluna-item-total">
            <span>Valor Bonif:</span>
          </div>
          <div className="coluna-item-total">
            <span>
              {`R$ ${new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(somaValTotalBonf)}
        `}
            </span>
          </div>
        </Row>
      </ContainerTotais>
    </>
  );
};
