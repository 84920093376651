import styled from 'styled-components';

export const Container = styled.div`
  .MuiTableCell-body {
    padding: 0;
  }

  width: 100%;
  height: 100%;
  padding: 20px;
  @media screen and (max-width: 1245px) {
    .tamanhoBuscaProduto {
      .react-select__control {
        min-width: 430px !important;
      }
      .containerButton button {
        margin-left: 115px !important;
      }
    }
    .tamanhoInput {
      margin-left: 100px !important;
      input {
        min-width: 100px;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .tamanhoBuscaProduto {
      .react-select__control {
        min-width: 400px !important;
      }
      .containerButton button {
        margin-left: 115px !important;
      }
    }
    .tamanhoInput {
      margin-left: 10px !important;
      input {
        min-width: 100px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .tamanhoBuscaProduto {
      .react-select__control {
        min-width: 200px !important;
      }
      .containerButton button {
        margin-left: 0px !important;
      }
    }
    .tamanhoInput {
      margin-left: -50px !important;
      input {
        min-width: 90px !important;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .tamanhoBuscaProduto {
      .react-select__control {
        min-width: 50px !important;
      }
      .containerButton button {
        margin-left: 60% !important;
      }
    }
    .tamanhoInput {
      margin-top: 25px !important;
      margin-left: -50px !important;
      input {
        min-width: 200px !important;
      }
    }
  }
  @media screen and (max-width: 1399px) {
    .buttonAdicionar {
      .ViVmT.confirm {
        margin-left: 846px !important;
        background-color: #28a745 !important;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .buttonAdicionar {
      .ViVmT.confirm {
        margin-left: 667px !important;
        background-color: #28a745 !important;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .buttonAdicionar {
      .ViVmT.confirm {
        margin-left: 427px !important;
        background-color: #28a745 !important;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .buttonAdicionar {
      .ViVmT.confirm {
        margin-left: 248px !important;
        background-color: #28a745 !important;
      }
    }
  }
  .tamanhoBuscaProduto {
    .react-select__control {
      min-width: 650px;
    }
    .containerButton button {
      margin-left: 150px;
    }
  }
  .tamanhoInput {
    margin-left: 230px;
    input {
      min-width: 200px;
    }
  }

  #containe-busca-produto {
    padding: 0;
    margin: 0;
  }

  .table_fornecedor {
    width: 100%;
  }
  .table_fornecedor tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table_fornecedor tbody tr td {
    border-left: 1px solid #dee2e6;
    vertical-align: middle;
  }
  .table_fornecedor tbody tr td {
    width: auto;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    box-sizing: content-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table_fornecedor thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  @media screen and (max-width: 767px) {
    .table_fornecedor {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  }
`;

export const ContainerTable = styled.div`
  min-height: 250px;
  margin-top: 25px;
  @media (max-width: 1200px) {
    margin-bottom: 50px;
  }
  @media (max-width: 1000px) {
    margin-bottom: 100px;
  }
  @media (max-width: 600px) {
    margin-bottom: 200px;
  }
`;

export const ContainerAcoes = styled.div`
  width: 100px;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  transition: all 0.5s;
  button {
    background: none;
    border: none;
    svg {
      font-size: 20pt;
    }
  }
`;

export const ContainerTotais = styled.div`
  width: 100%;
  position: relative;
  min-height: 40px;
  background: #daebf6;
  font-weight: 600;
  padding: 10px;
  display: flex;
  align-items: center;
  @media (min-width: 1000px) {
    white-space: nowrap;
  }
  #linha-item-total {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .coluna-item-total {
    width: auto;
  }
`;

export const Title = styled.h3`
  margin-top: 20px;
  margin-bottom: 0px;
  color: #313638;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 40px;
`;
export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 42px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const SeparatorLine = styled.p`
  margin-top: 40px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ededed;
  height: 5px;
  width: 99.95%;
`;

export const ButtonCancel = styled.button`
  height: 40px;
  min-width: 85px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  margin: 0 5px;
  background-color: #dc3545;
  &:disabled {
    background-color: #e3bec1;
    cursor: not-allowed;
    &:hover {
      background-color: #e3bec1;
      opacity: 1;
    }
    // eslint-disable-next-line prettier/prettier
  }
  &:hover {
    opacity: 0.7;
  }
  box-shadow: transparent !important;
`;
export const ContainerLoader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonSeparator = styled.button`
  float: right;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;
  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  &:focus {
    outline: 0;
  }
  svg {
    color: #eda934 !important;
  }
`;
