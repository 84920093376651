import React from 'react';
import { SelectProps } from '@material-ui/core';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { InputSelect } from '~/components/NovosInputs';
import { useAlteracaoCustoNfBonificada } from '../../AlteracaoNfBonificadaContext';
import { Produto } from '../../protocols';
import './style';

const optionsRatear = [
  {
    value: 0,
    label: 'Valor',
  },
  {
    value: 1,
    label: 'Quantidade',
  },
];

export const TableFornecedor: React.FC = () => {
  const {
    nfsSelecionadas,
    produtos,
    register,
    nfEntrada,
    setProdutos,
    setValue,
    reset,
    control,
    handleResetStates,
  } = useAlteracaoCustoNfBonificada();

  return (
    <>
      <Row>
        <Col md={12} sm={12} xs={12} className="mt-2">
          <table className="table_fornecedor">
            <thead>
              <tr>
                <th>Loja</th>
                <th>Fornecedor</th>
                <th>CNPJ</th>
                <th>NF</th>
                <th>Entrada</th>
                <th>Ratear por:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{nfEntrada.cod_loja}</td>
                <td>{`${nfEntrada.cod_fornecedor} - ${nfEntrada.des_fornecedor}`}</td>
                <td>{nfEntrada.num_cpf_cnpj_view}</td>
                <td>{nfEntrada.num_nf}</td>
                <td>{moment(nfEntrada.dta_entrada).format('DD/MM/YYYY')}</td>
                <td
                  style={{
                    width: '10rem',
                    minWidth: '5rem',
                    paddingBottom: '0.2rem',
                  }}
                >
                  <InputSelect
                    label=""
                    placeholder=""
                    name="ratear"
                    register={register}
                    isDisabled={nfsSelecionadas.length > 0}
                    isError={false}
                    control={control}
                    options={optionsRatear}
                    changeSelected={(selected: SelectProps) => {
                      reset();
                      produtos.forEach((prod: Produto) => {
                        prod.qtd_rateada = 0;
                        prod.val_rateio = 0;
                      });
                      setProdutos(produtos);
                      handleResetStates();
                      setValue('ratear', selected);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};
