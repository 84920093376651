import React, { useEffect, useRef, useState } from 'react';
import { useAlteracaoCustoNfBonificada } from '../../AlteracaoNfBonificadaContext';
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  Table,
  makeStyles,
} from '@material-ui/core';
import { InputMoney, InputPercent } from '~/components/NovosInputs';
import { moneyFormat, transformAsCurrency } from '~/utils/functions';
import { ContainerTotais } from '../../style';
import { Row } from 'react-bootstrap';
import { CalcSaldoRateio } from '../../functions/CalculaSaldoRateio';
import { Produto } from '../../protocols';
import { CalculaPercentual } from '../../functions/CalculaPercentual';

export const TableProduto: React.FC = () => {
  const [pageProduto, setPageProduto] = useState<number>(0);
  const [rowsPerPageProduto] = useState<number>(5);
  const tableRef = useRef<any>();

  const {
    nfsSelecionadas,
    produtos,
    register,
    control,
    somaValTotalBonf,
    somaQtdTotalBonf,
    setProdutos,
    setValue,
    watch,
    errors,
    vSaldoValor,
    setVSaldoValor,
    vSaldoQtd,
    setvSaldoQtd,
    vSaldoPerc,
    setVSaldoPerc,
    clearErrors,
  } = useAlteracaoCustoNfBonificada();
  const ratear = watch('ratear')?.value;

  const handleChangePageProduto = async (
    event: unknown,
    newPage: number,
  ): Promise<void> => {
    tableRef.current.scrollTop = 0;
    setPageProduto(newPage);
  };

  const calculateFields = (
    newValue: string,
    cod_produto: any,
    cod_seq_nf_item: any,
    name: string,
  ): void => {
    const idx = produtos.findIndex(
      (item) =>
        item.cod_produto === cod_produto &&
        item.cod_seq_nf_item === cod_seq_nf_item,
    );
    setValue(name, newValue);
    clearErrors(name);
    let valorTotal = 0;
    let valorDesconto = 0;
    let valorTabelaFinal = 0;
    const updatedProdutos = [...produtos];
    const produto = updatedProdutos[idx];
    ratear === 0
      ? ((produto.val_rateio = transformAsCurrency(newValue)),
        (produto.per_rateio = 0))
      : ((produto.per_rateio = transformAsCurrency(newValue)),
        (produto.val_rateio = 0));
    if (nfsSelecionadas.length === 0) {
      calculSaldoRestante(updatedProdutos);
      return;
    }

    if (produto.per_rateio > 0 || produto.val_rateio > 0) {
      valorTabelaFinal = produto.val_tabela_final;
      if (ratear === 1) {
        produto.val_rateio = 0;
        valorTotal = valorTabelaFinal;
        produto.qtd_rateada =
          somaQtdTotalBonf * (produto.per_rateio / 100) +
          produto.qtd_total_bonificado_rateado;
        produto.val_custo_rep_novo =
          valorTotal / (produto.qtd_total + produto.qtd_rateada);
      } else {
        if (somaValTotalBonf !== null) {
          valorDesconto = CalculaPercentual(
            somaValTotalBonf,
            produto.per_rateio,
          );
        }
        valorTotal = valorTabelaFinal - valorDesconto;
        if (produto.val_rateio > 0) {
          produto.qtd_rateada = produto.val_rateio / produto.val_custo_rep;
          produto.qtd_rateada += produto.qtd_total_bonificado_rateado;
          produto.val_custo_rep_novo =
            produto.val_tabela_final /
            (produto.qtd_total + produto.qtd_rateada);
        } else {
          produto.val_custo_rep_novo = valorTotal / produto.qtd_total;
        }
      }
    } else {
      produto.val_custo_rep_novo = produto.val_custo_rep;
      produto.qtd_rateada = 0;
      produto.val_rateio = 0;
    }
    if (produto.per_desp_op > 0) {
      produto.val_custo_rep_novo += produto.per_desp_op;
    }
    calculSaldoRestante(updatedProdutos);
    setProdutos(updatedProdutos);
  };

  function calculSaldoRestante(prods: Produto[]) {
    let total_per_rateio = 0;
    let total_val_rateio = 0;
    let total_qtd_rateada = 0;
    let total_qtd_bonificado_rateado = 0;
    prods.forEach((prod: Produto) => {
      total_per_rateio += prod.per_rateio;
      total_val_rateio += prod.val_rateio;
      total_qtd_rateada += prod.qtd_rateada ? prod.qtd_rateada : 0;
      total_qtd_bonificado_rateado += prod.qtd_total_bonificado_rateado;
    });

    if (total_qtd_rateada > 0) {
      const result = CalcSaldoRateio(
        total_per_rateio,
        total_qtd_rateada - total_qtd_bonificado_rateado,
        total_val_rateio,
        somaQtdTotalBonf,
        somaValTotalBonf,
        ratear,
      );

      setVSaldoValor(ratear === 1 ? somaValTotalBonf : result.pValBonif);
      setvSaldoQtd(ratear === 0 ? somaQtdTotalBonf : result.pQtdRateio);
      setVSaldoPerc(ratear === 0 ? 100 : result.vSaldoRateio);
    } else {
      const result = CalcSaldoRateio(
        total_per_rateio,
        total_qtd_rateada,
        total_val_rateio,
        somaQtdTotalBonf,
        somaValTotalBonf,
        ratear,
      );
      setVSaldoValor(ratear === 1 ? somaValTotalBonf : result.pValBonif);
      setvSaldoQtd(ratear === 0 ? somaQtdTotalBonf : result.pQtdRateio);
      setVSaldoPerc(ratear === 0 ? 100 : result.vSaldoRateio);
    }
  }

  useEffect(() => {
    calculSaldoRestante(produtos);
  }, [somaQtdTotalBonf, somaValTotalBonf, produtos, ratear]);

  const useStyles = makeStyles(() => ({
    customWidth: {
      maxWidth: 550,
      fontSize: 12,
    },
  }));

  const styles = useStyles();

  return (
    <>
      <Paper
        style={{
          width: '100%',
          flex: 1,
          zIndex: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TableContainer
          ref={tableRef}
          style={{ maxHeight: 320, minHeight: 320 }}
        >
          <Table stickyHeader>
            <TableHead style={{ whiteSpace: 'nowrap' }}>
              <TableRow>
                <TableCell align="center">Item</TableCell>
                <TableCell align="center">Código</TableCell>
                <TableCell align="center">Descrição</TableCell>
                <TableCell align="center">Qtd. Total NF</TableCell>
                <TableCell align="center">Qtd. Rateada</TableCell>
                <TableCell align="center">Qtd. Já Rateada</TableCell>
                <TableCell align="center">Total Final</TableCell>
                <TableCell align="center">Custo Rep.</TableCell>
                <TableCell align="center">Custo Rep. Rateado</TableCell>
                {ratear === 0 ? (
                  <TableCell align="center">Valor</TableCell>
                ) : (
                  <TableCell align="center">% Rateio</TableCell>
                )}
                <TableCell>Novo Custo Rep.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {produtos.length > 0 &&
                produtos
                  .slice(
                    pageProduto * rowsPerPageProduto,
                    pageProduto * rowsPerPageProduto + rowsPerPageProduto,
                  )
                  .map((produto: any) => (
                    <Tooltip
                      key={`${produto.cod_produto}_${produto.cod_seq_nf_item}`}
                      title={`Item: ${produto.num_item} - ${produto.des_produto} - Código do Produto: ${produto.cod_produto}`}
                      placement="bottom"
                      classes={{ tooltip: styles.customWidth }}
                    >
                      <TableRow>
                        <TableCell align="center">{produto.num_item}</TableCell>
                        <TableCell align="center">
                          {produto.cod_produto}
                        </TableCell>

                        <TableCell
                          style={{
                            maxWidth: '9.375rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          align="center"
                        >
                          {produto.des_produto}
                        </TableCell>

                        <TableCell align="center">
                          {produto.qtd_total}
                        </TableCell>
                        <TableCell align="center">
                          {produto.qtd_rateada
                            ? new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(produto.qtd_rateada)
                            : '0'}
                        </TableCell>
                        <TableCell align="center">
                          {produto.qtd_total_bonificado_rateado
                            ? new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(produto.qtd_total_bonificado_rateado)
                            : '0'}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          align="center"
                        >
                          {`R$ ${moneyFormat(produto.val_tabela_final)}`}
                        </TableCell>
                        <TableCell align="center">
                          {`R$ ${moneyFormat(produto.val_custo_rep_calculado)}`}
                        </TableCell>
                        <TableCell align="center">
                          {produto.val_custo_rep_novo
                            ? `R$ ${moneyFormat(produto.val_custo_rep_novo)}`
                            : `R$ ${moneyFormat(produto.val_custo_rep)}`}
                        </TableCell>
                        {ratear === 0 ? (
                          <TableCell align="center">
                            <div
                              style={{
                                marginBottom: '10px',
                              }}
                            >
                              <InputMoney
                                name={`val_rateio_${produto.cod_produto}_${produto.cod_seq_nf_item}`}
                                register={register}
                                placeholder="0,00"
                                style={{
                                  width: '100px',
                                  margin: '0px !important',
                                }}
                                isError={
                                  !!errors[
                                    `val_rateio_${produto.cod_produto}_${produto.cod_seq_nf_item}`
                                  ]
                                }
                                maxLength={10}
                                min={0}
                                onBlur={(e: any) =>
                                  calculateFields(
                                    e.target.value,
                                    produto.cod_produto,
                                    produto.cod_seq_nf_item,
                                    `val_rateio_${produto.cod_produto}_${produto.cod_seq_nf_item}`,
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <div
                              style={{
                                marginBottom: '25px',
                              }}
                            >
                              <InputPercent
                                style={{
                                  width: '6rem',
                                }}
                                placeholder="0,00"
                                name={`per_rateio_${produto.cod_produto}_${produto.cod_seq_nf_item}`}
                                register={register}
                                control={control}
                                isError={
                                  !!errors[
                                    `per_rateio_${produto.cod_produto}_${produto.cod_seq_nf_item}`
                                  ]
                                }
                                onBlur={(e: any) =>
                                  calculateFields(
                                    e.target.value,
                                    produto.cod_produto,
                                    produto.cod_seq_nf_item,
                                    `per_rateio_${produto.cod_produto}_${produto.cod_seq_nf_item}`,
                                  )
                                }
                                max={100}
                                min={0}
                              />
                            </div>
                          </TableCell>
                        )}
                        <TableCell align="center">
                          {produto.val_custo_rep_novo
                            ? `R$ ${moneyFormat(produto.val_custo_rep_novo)}`
                            : `R$ ${moneyFormat(produto.val_custo_rep)}`}
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[-1]}
          onPageChange={handleChangePageProduto}
          count={produtos.length}
          page={pageProduto}
          rowsPerPage={rowsPerPageProduto}
        />
      </Paper>
      <ContainerTotais>
        <Row id="linha-item-total">
          <div className="coluna-item-total">
            <span>Saldo Aplicado</span>
          </div>
          <div className="coluna-item-total">
            <span>Percentual:</span>
          </div>
          <div className="coluna-item-total">
            <span
              style={{
                color: `${vSaldoPerc < 0 ? 'red' : 'black'}`,
              }}
            >
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(vSaldoPerc)}
              %
            </span>
          </div>
          <div className="coluna-item-total">
            <span>Qtde:</span>
          </div>
          <div className="coluna-item-total">
            <span
              style={{
                color: `${vSaldoQtd < 0 ? 'red' : 'black'}`,
              }}
            >
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(vSaldoQtd)}
            </span>
          </div>
          <div className="coluna-item-total">
            <span>Valor:</span>
          </div>
          <div className="coluna-item-total">
            <span
              style={{
                color: `${vSaldoValor < 0 ? 'red' : 'black'}`,
              }}
            >
              {`R$ ${new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(vSaldoValor)}
                  `}
            </span>
          </div>
        </Row>
      </ContainerTotais>
    </>
  );
};
